var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("article", { staticClass: "about" }, [
    _c("h1", [_vm._v("About Dashy")]),
    _vm._m(0),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _vm._m(4),
    _c("section", { staticClass: "license" }, [
      _c("h2", [_vm._v("License")]),
      _c("code", [
        _vm._v(
          " Copyright © " +
            _vm._s(new Date().getFullYear()) +
            " Alicia Sykes (https://aliciasykes.com) "
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("code", [
        _vm._v(
          " Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the “Software”), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions: "
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("code", [
        _vm._v(
          " The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. "
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("code", [
        _vm._v(
          " THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWAREOR THE USE OR OTHER DEALINGS IN THE SOFTWARE. "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("section", [
      _c("h2", [_vm._v("Project Overview")]),
      _c("p", [
        _vm._v(
          " Dashy is a self-hosted startpage for keeping your running applications and services organised. The aim of Dashy is to provide an easy-to-deploy, highly customizable and functianl dashboard, in order to improvde productivity and enable easy organisation of running services or web links. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("section", [
      _c("h2", [_vm._v("Source")]),
      _c("p", [
        _vm._v(" Dashy's source is available through "),
        _c("a", { attrs: { href: "https://github.com/lissy93/dashy" } }, [
          _vm._v("GitHub"),
        ]),
        _vm._v(", and the Docker image is on "),
        _c("a", { attrs: { href: "https://hub.docker.com/r/lissy93/dashy" } }, [
          _vm._v("DockerHub"),
        ]),
        _vm._v(". "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("section", [
      _c("h2", [_vm._v("Reporting a Bug")]),
      _c("p", [
        _vm._v(" If you've come across an bug in Dashy, please "),
        _c("a", { attrs: { href: "https://git.io/JnqPR" } }, [
          _vm._v("open an issue"),
        ]),
        _vm._v(", so that it can be fixed. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("section", [
      _c("h2", [_vm._v("Getting Support")]),
      _c("p", [
        _vm._v(
          " If you need any help deploying, using, or developing Dashy, feel free to "
        ),
        _c("a", { attrs: { href: "https://git.io/JnqiA" } }, [
          _vm._v("open a support ticket"),
        ]),
        _vm._v(" or start a "),
        _c(
          "a",
          { attrs: { href: "https://github.com/Lissy93/dashy/discussions" } },
          [_vm._v("discussion")]
        ),
        _vm._v(" on GitHub. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("section", [
      _c("h2", [_vm._v("About the Author")]),
      _c("a", { attrs: { href: "https://aliciasykes.com" } }, [
        _c("img", {
          staticClass: "aht-pic",
          attrs: {
            src: "https://i.ibb.co/FnxqTfx/aht-bot-round.png",
            alt: "Alicia Sykes",
          },
        }),
      ]),
      _vm._v(" Dashy is developed an maintained by "),
      _c("a", { attrs: { href: "https://aliciasykes.com" } }, [
        _vm._v("Alicia Sykes"),
      ]),
      _vm._v(" ("),
      _c("a", { attrs: { href: "https://github.com/lissy93" } }, [
        _vm._v("@Lissy93"),
      ]),
      _vm._v("), with support from the community. "),
      _c("ul", [
        _c("li", [
          _c("b", [_vm._v("PGP Key")]),
          _vm._v(" - "),
          _c(
            "a",
            {
              attrs: {
                href: "https://keybase.io/aliciasykes/pgp_keys.asc?fingerprint=0688f8d34587d954e9e51fb8fedb68f55c0283a7",
              },
            },
            [
              _c("code", [
                _vm._v("0688 F8D3 4587 D954 E9E5 1FB8 FEDB 68F5 5C02 83A7"),
              ]),
            ]
          ),
        ]),
        _c("li", [
          _c("b", [_vm._v("BTC Address")]),
          _vm._v(" - "),
          _c("code", [_vm._v("3853bSxupMjvxEYfwGDGAaLZhTKxB2vEVC")]),
        ]),
        _c("li", [
          _c("b", [_vm._v("Mail")]),
          _vm._v(" - "),
          _c("code", [_vm._v("alicia at omg dot lol")]),
        ]),
        _c("li", [
          _c("b", [_vm._v("Blog")]),
          _vm._v(" - "),
          _c("a", { attrs: { href: "https://notes.aliciasykes.com" } }, [
            _vm._v("notes.aliciasykes.com"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }